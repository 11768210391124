import React from "react";
import { Row, Col } from "react-bootstrap";
import "./navbar.css";
import { BsSearch } from "react-icons/bs";
import { IoIosNotifications } from "react-icons/io";
import Pakistan from "../../assets/image/PakistanFlag.png";
import User from "../../assets/image/user.png";
export default function NavBar() {
  return (
    <>
      <div className="nav-Bar-Main-Div">
        <Row className="nav-Bar-Main-Row">
          <Col className="nav-Bar-Col" xm={12} sm={12} md={12} lg={3} xl={3}>
            <BsSearch size={25} />
            <IoIosNotifications size={25} />
            <img className="flag-Imag" src={Pakistan} />
            <p className="user-Name-Text">Hi,Adan</p>
            <img className="user-Image" src={User} />
          </Col>
        </Row>
      </div>
    </>
  );
}
