import logo from "./logo.svg";
import Reactrouter from "./config/router";
function App() {
  return (
    <div className="App">
      <Reactrouter />
    </div>
  );
}

export default App;
