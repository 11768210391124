import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./dash-board..css";
import NavBar from "../../component/navbar/navbar";
import Card from "../../component/card/card";

export default function DashBoard(props) {
  const cardData = [
    {
      Name: "Admin",
      Age: "20",
      Information: "our commission",
      Number: "20%",
    },
    {
      Name: "Fleet",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#64D2FF",
      ImageBackColor: "#64D2FF",
    },
    {
      Name: "Vendor",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#EE9D01",
      ImageBackColor: "#EE9D01",
    },
    {
      Name: "Advertiser",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#1BC5BD",
      ImageBackColor: "#1BC5BD",
    },
    {
      Name: "Data analyst",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#7337EE",
      ImageBackColor: "#7337EE",
    },
    {
      Name: "user",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#187DE4",
      ImageBackColor: "#187DE4",
    },
    {
      Name: "sale agent",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#4A7DFF",
      ImageBackColor: "#4A7DFF",
    },
    {
      Name: "agency",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#3699FF",
      ImageBackColor: "#3699FF",
    },
    {
      Name: "waiter",
      Age: "20",
      Information: "our commission",
      Number: "20%",
      backgroundColor: "#32D74B",
      ImageBackColor: "#32D74B",
    },
  ];
  return (
    <>
      <div style={{ backgroundColor: "#FAFAFA", height: "600px" }}>
        <NavBar />
        <Container>
          <Row>
            {cardData.map((item) => {
              return (
                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Card
                    Name={item.Name}
                    Age={item.Age}
                    Information={item.Information}
                    Number={item.Number}
                    backgroundColor={item.backgroundColor}
                    ImageBackColor={item.ImageBackColor}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}
