import React from "react";
import "./forgotpass.css";
import { Row, Col } from "react-bootstrap";
import Input from "../../component/input/input";
import Button from "../../component/button/button";

export default function ForgotPassword() {
  return (
    <>
      <div className="forGot-Main-Div">
        <Row className="forgot-Main-Row">
          <Col
          className="text-Col"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <h3 className="want-To-Be-Text">want to be a hf tech</h3>
            <h3 className="want-To-Be-Text"> merchant? (canada)</h3>
            <p className="leave-Your-Res">
              Leave your restaurant details here and we will be in touch
              shortly.
            </p>
          </Col>
          <Col
          className="inputs-Col"
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
          >
            <Input Text="Enter Email Address" />
            <Button width={"100%"} Text="Next" />
          </Col>
        </Row>
      </div>
    </>
  );
}
