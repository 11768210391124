import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Switch } from "react-router";
import Login from "../screens/login";
import Forgotpass from "../screens/forgotPassword/forgotpass";
import SignIn from "../screens/sign-Up/sign-in";
import DashBoard from "../screens/Dashboard/dash-board";

export default function ReactRouter() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exacr path="/forgotpass">
            <Forgotpass />
          </Route>
          <Route exact path="/signin"> 
            <SignIn/>
          </Route>
          <Route exact path="/dashboard">
            <DashBoard/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
