import React from "react";
import { Row, Col } from "react-bootstrap";
import "./signIn.css";
import Button from "../../component/button/button";
import Input from "../../component/input/input";

export default function SignIn() {
  return (
    <>
      <div className="sign-In-Main-Div">
        <Row className="sign-In-Main-Row">
          <Col
            className="signin-want-To-Be-Col"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <h3 className="want-To-Be-Text">want to be a hf tech</h3>
            <h3 className="want-To-Be-Text"> merchant? (canada)</h3>
            <p className="leave-Your-Res">
              Leave your restaurant details here and we will be in touch
              shortly.
            </p>
          </Col>
          <Col className="inputs-Col" xs={12} sm={12} md={12} lg={9} xl={9}>
            <Input Text="First Name" />
            <Input Text="Last Name" />
            <Input Text="Email Addrees" />
            <Input Text="Password" />
            <Input Text="Confirm Password" />
            <Button width={"100%"} Text="Submit" />
            <p className="by-Proceeding-Text">
              By proceeding, I agree that you can collect, use and disclose the
              information provided by me in accordance with your
              <span className="privacy-Text">Privacy Policy</span> which I have
              and understand
            </p>
            <p className="already-Have-Text">
              Already have Account?
              <a href="/" className="login-Text">
                Login
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}
