import React from "react";
import "./index.css";
import { Row, Col, Container } from "react-bootstrap";
import Inputs from "../../component/input/input";
import Buttons from "../../component/button/button";
import { useHistory } from "react-router-dom";

export default function Login() {
  let history = useHistory();
  const dash = () => {
    history.push("/dashboard");
  };
  return (
    <>
      <div className="main-Div">
        <Row className="container-Row">
          <Col className="containerCol" xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="headin-Main-Div">
              <p className="headin-Welcome">Welcome to HT TECH</p>
              <div className="new-Create-Text-Div">
                <p className="new-Here-Text">New Here?</p>
                <a className="create-An-Text" href="/signin">
                  Create an Account
                </a>
              </div>
            </div>
          </Col>
          <Col className="input-Col" xs={12} sm={12} md={12} lg={10} xl={10}>
            <Inputs Text="Enter Email Address " />
            <Inputs Text="Enter Password " />
            <a href="/forgotpass" className="for-Got-Pass-Text">
              Forgot Password?
            </a>
          </Col>
          <Col
          className="btn-Col"
            xs={12}
            sm={12}
            md={12}
            lg={10}
            xl={10}
          >
            <Buttons width={"101px"} onClick={dash} Text="sign in" />
            <Buttons
              width={"216px"}
              backgroundColor={"#EFEFF4"}
              color={"#156985"}
              Text="Sign In With Google"
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
