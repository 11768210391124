import React from "react";
import { Row } from "react-bootstrap";
import "./input.css";

export default function Inputs(props) {
  return (
    <>
      <Row style={{ marginBottom: "22px" }}>
        <input placeholder={props.Text} />
      </Row>
    </>
  );
}
