import React from "react";
import "./button.css";
import { Row } from "react-bootstrap";
import {FcGoogle} from "react-icons/fc"
export default function Buttons(props) {
  return (
    <>
      <button
        style={{ color: props.color, width: props.width, backgroundColor: props.backgroundColor }}
        {...props}
        className="buttons"
      >
        {/* <FcGoogle/> */}
        {props.Text}
      </button>
    </>
  );
}
