import React from "react";
import { Col, Row } from "react-bootstrap";
import "./card.css";
import AdminUser from "../../assets/image/adminUser.png";

export default function Card(props) {
  return (
    <>
      <div className="card-Main-Div">
        <div className="dot-Main-Div">
          <div style={{backgroundColor: props.backgroundColor}} className="pointer"></div>
        </div>
        <div className="admin-Name-Information-Text-Main-Div">
          <div className="user-Name-Text-Div">
            <p className="name-Text">{props.Name}</p>
            <p className="number-Text">{props.Age}</p>
          </div>
          <div className="user-Infor-Text">
            <p className="name-Text">{props.Information}</p>
            <p className="number-Text">{props.Number}</p>
          </div>
        </div>
        <div className="admin-User-Image">
          <img style={{backgroundColor: props.ImageBackColor}} className="admin-Uaser" src={AdminUser} />
        </div>
      </div>
    </>
  );
}
